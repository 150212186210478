import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Index(req) {
  const route = useRouter();
  useEffect(() => {
    route.push('/');
  }, []);
  return (
    <div className="hello"></div>
  );
}
